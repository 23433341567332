import { withPrefix } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layouts/layout';
import BreadCrumb from '../components/ProductMain/BreadCrumb';
import ProductMain from '../components/ProductMain/ProductMain';

const SkinBrighteningFacialKit = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Rose And Gold Skin Brightening Facial Kit For Gold Like Glow - Cleansing Milk, F - Default
          Title - VasuStore
        </title>
        <meta name="facebook-domain-verification" content="7zavsnqe2ti2yx2faj448nyhtkrxg5" />
        <meta
          name="title"
          content="Rose And Gold Skin Brightening Facial Kit For Gold Like Glow - Cleansing Milk, F - Default Title - VasuStore"
        />
        <meta
          name="description"
          content="Rose and Gold Skin Brightening Facial Kit For Gold Like Glow - Cleansing Milk, Face Scrub, Facial Massaging Cream, Face Pack - 4 Easy Steps For Bright and Radiant Skin - Usage Upto 7 Times For Facial"
        />
        <meta
          name="keywords"
          content="Rose And Gold Face Wash, Cleansing Face Wash, Skincare, Face, Face Wash, Facecare"
        />
        {/* Global site tag (gtag.js) - Google Analytics  */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-1LB4M0W7V9" />
        <script src={withPrefix('js/script.js')} type="text/javascript" />
      </Helmet>
      <Layout>
        <BreadCrumb productType="kit" productSelected="Skin Brightening Facial Kit" />
        <ProductMain productType="kit" productSelected="skin-brightening-facial-kit" />
      </Layout>
    </>
  );
};

export default SkinBrighteningFacialKit;
